<template>
  <div class="set-remarks">
    <h1 class="title">设置家人备注</h1>
    
    <div class="tags-container">
      <!-- 第一行 -->
      <div 
        class="tag"
        :class="{ active: item === selectedRemarks }"
        v-for="(item, index) in remarksList"
        :key="index" 
        @click="handleSelectRemarks(item)"
      >
        {{ item }}
      </div>
      <div class="tag custom" @click="isAddRemarksDialogVisible = true">+ 自定义</div>
    </div>
    <!-- 下一步按钮 -->
    <div class="next-btn">
      <button @click="nextStep">下一步</button>
    </div>
    <!-- 添加备注弹窗 -->
    <van-overlay :show="isAddRemarksDialogVisible" @click="isAddRemarksDialogVisible = false">
        <div class="add-remarks-dialog" @click.stop>
            <div class="add-remarks-dialog__header">
                <span>自定义备注</span>
            </div>
            <div class="input-cell">
                <van-field v-model="editingRemarks" placeholder="请输入备注" />
            </div>
            <div class="add-remarks-dialog__footer">
                <button class="add-remarks-dialog__cancel" @click="isAddRemarksDialogVisible = false">取消</button>
                <button :class="{ active: editingRemarks }" class="add-remarks-dialog__confirm" @click="handleAddRemarksSubmit">下一步</button>
            </div>
        </div>
    </van-overlay>
  </div>
</template>

<script>
import { Field, Overlay, Toast } from 'vant';
import { getRemarksList } from '@/api/family';
export default {
    name: 'SetRemarks',
    components: {
        [Field.name]: Field,
        [Overlay.name]: Overlay
    },
    data() {
        return {
            isAddRemarksDialogVisible: false,
            editingRemarks: '',
            selectedRemarks: '',
            remarksList: []
        }
    },
    methods: {
        nextStep() {
          if (!this.selectedRemarks) {
            Toast('请选择备注')
            return
          }
          this.$router.push({ 
              path: '/family/share', 
              query: {
                  role: this.$route.query.role,
                  remarks: this.selectedRemarks
              }
          })
        },
        handleSelectRemarks(item) {
          this.selectedRemarks = item
        },
        handleAddRemarksSubmit() {
          this.isAddRemarksDialogVisible = false
          if (!this.editingRemarks) {
              Toast('请输入备注')
              return
          }
          this.$router.push({ 
              path: '/family/share', 
              query: {
                  role: this.$route.query.role,
                  remarks: this.editingRemarks
              }
          })
        },
        initData() {
            getRemarksList().then(res => {
                this.remarksList = res
            }).finally(() => {
                this.$toast.clear()
            })
        }       
    },
    mounted() {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,   // 禁止背景点击
        duration: 0,  //展示时间，0为持续展示
        overlay: true,  //是否显示背景遮罩层
      })  
      this.initData()
    }       
}
</script>

<style lang="less" scoped>
.set-remarks {
  padding: .9rem .6rem 2rem;
  position: relative;

  .title {
    margin: 0;
    font-size: .64rem;
    line-height: .76rem;
    color: #333;
    font-weight: 500;
  }

  .tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: .2rem;
    padding-top: .6rem;

    .tag {
      padding: .16rem .24rem;
      background: #EFEFEF;
      border-radius: .2rem;
      font-size: .28rem;
      line-height: .36rem;
      cursor: pointer;
      color: #333;
      font-weight: 500;

      &.active {
        background: #68D4DC;
        color: #fff;
      }

      &.custom {
        color: #3BBDD8;
        font-weight: 600;
      }
    }
  }
  .next-btn {
    position: fixed;
    bottom: .88rem;
    left: .94rem;
    right: .94rem;
    
    button {
      width: 100%;
      background: #68D4DC;
      border: none;
      border-radius: .58rem;
      color: #fff;
      font-size: .36rem;
      line-height: .84rem;
      height: .84rem;
    }
  }
  .add-remarks-dialog {
    width: 6.72rem;
    background: #F1F1F1;
    border-radius: .22rem;
    box-sizing: border-box;   
    padding: .44rem .34rem .34rem;
    border-radius: .54rem; 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);   
    &__header {
        padding: 0;
        font-size: .36rem;
        line-height: .42rem;
        color: #313131;
        font-weight: 400;
        text-align: center;
    }
    .input-cell {
        margin: .44rem 0;
        border-radius: .22rem;
        overflow: hidden;   
        border: .01rem solid #848484;
        :deep(.van-cell) {
        padding: .2rem .32rem;
      }
    }   
    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__cancel, &__confirm {
        flex: none;
        width: 2.58rem;
        height: .86rem;
        background: #DEDEDE;
        border-radius: .24rem;
        font-size: .36rem;
        line-height: .86rem;
        color: #666666;
        font-weight: 400;
        border: none;
        outline: none;
    }
    &__confirm.active {
      color: #3BBDD8;
    }
  }
}
</style>
